<template>
  <Toast />
  <div>
    <div class="layout-dashboard">
      <div class="grid">
        <div class="col-12 lg:col-12">
          <div class="card card-w-title statistics">
            <Button label="Crear ticket" @click="openModal" />
          </div>
        </div>
      </div>
    </div>

    <div class="layout-dashboard">
      <div class="grid">
        <div class="col-12 lg:col-12">
          <div class="card card-w-title statistics">
            <Dropdown
              v-model="selectedStatusFilter"
              :options="statusFilter"
              placeholder="Filtrar por estado"
              @change="getTicketsPage"
              optionLabel="name"
              showClear
            />
            <DataTable
              :value="tickets"
              :rows="10"
              :emptyMessage="'No hay tickets'"
              :totalRecords="totalItems"
              :paginator="true"
              paginatorTemplate="CurrentPageReport"
              :currentPageReportTemplate="`Total de tickets: ${totalItems}`"
              :loading="ticketsLoading"
              :loadingIcon="'pi pi-spinner'"
              @sort="onSortChange"
            >
              <template #paginatorend>
                <Button
                  icon="pi pi-angle-left"
                  @click="onPageChange(currentPage - 1)"
                  outlined
                  :disabled="currentPage === 1"
                  class="change-page-btn"
                />
                <span v-for="page in Math.ceil(totalItems / 10)" :key="page">
                  <Button
                    :label="page"
                    @click="onPageChange(page)"
                    :class="
                      currentPage === page
                        ? 'page-btn current-page-btn'
                        : 'page-btn'
                    "
                    outlined
                  />
                </span>
                <Button
                  icon="pi pi-angle-right"
                  @click="onPageChange(currentPage + 1)"
                  outlined
                  :disabled="currentPage === Math.ceil(totalItems / 10)"
                  class="change-page-btn"
                />
              </template>
              <Column field="id" header="ID" />
              <Column field="delivery_number" header="Número de envío" />
              <Column
                field="comment"
                header="Comentario"
                style="
                  max-width: 300px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              />
              <Column header="Estado">
                <template #body="{ data }">
                  <Chip
                    :label="data.status_name"
                    :class="`status-${data.status_id}`"
                  />
                </template>
              </Column>
              <Column header="Fecha de creación" :sortable="true">
                <template #body="{ data }">
                  {{ formatDateTime(data.created_at) }}
                </template>
              </Column>
              <Column header="Detalle">
                <template #body="{ data }">
                  <Button
                    label="Ver detalle"
                    @click="openCommentsModal(data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>

    <Dialog
      :visible="createTicketModalVisible"
      :modal="true"
      :style="{ width: '50vw' }"
      @update:visible="createTicketModalVisible = $event.value"
    >
      <div id="form">
        <template v-if="showShipmentId">
          <label for="deliveryNumber">Número de envío:</label>
        </template>
        <InputText
          v-if="showShipmentId"
          id="deliveryNumber"
          v-model="deliveryNumber"
          disabled="true"
        />
        <label for="comment">Comentario:</label>
        <Textarea id="comment" v-model="comment" class="ticket-textarea" />
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          @click="createTicketModalVisible = false"
        />
        <Button
          label="Crear"
          icon="pi pi-check"
          @click="save"
          :disabled="createButtonDisabled || ticketsLoading"
        />
      </template>
    </Dialog>
    <Dialog
      :visible="ticketLinesModalVisible"
      :modal="true"
      :style="{ width: '50vw' }"
      @update:visible="ticketLinesModalVisible = $event.value"
    >
      <TabView :activeIndex="activeTab" @tab-change="activeTab = $event.index">
        <TabPanel header="Comentarios">
          <div v-if="ticketLines.length > 0">
            <div v-for="line in ticketLines" :key="line.id" class="ticket-line">
              <p>
                <strong>{{ line.user_name }}</strong> -
                {{ formatDateTime(line.created_at) }}
              </p>
              <p>{{ line.comment }}</p>
            </div>
          </div>
          <div v-else class="blank-state">
            <p>No hay comentarios</p>
          </div>
        </TabPanel>
        <TabPanel header="Historial">
          <div>
            <div
              v-for="line in ticketHistory"
              :key="line.id"
              class="ticket-line history"
            >
              <div class="status-movements">
                <Chip
                  :label="line.from_status_name"
                  :class="`status-${line.from_status}`"
                />
                <i class="pi pi-arrow-right" />
                <Chip
                  :label="line.to_status_name"
                  :class="`status-${line.to_status}`"
                />
              </div>
              <p>
                {{ formatDateTime(line.created_at) }}
              </p>
            </div>
          </div>
          <div class="ticket-line history">
            <div class="status-movements">
              <Chip label="Creado" class="status-1" />
            </div>
            <p>
              {{ formatDateTime(currentTicket.created_at) }}
            </p>
          </div>
        </TabPanel>
      </TabView>
      <template #footer>
        <Button
          label="Cerrar"
          icon="pi pi-times"
          @click="ticketLinesModalVisible = false"
        />
        <Button
          label="Agregar comentario"
          icon="pi pi-plus"
          @click="addTicketLineModalVisible = true"
          outlined
        />
      </template>
    </Dialog>
    <Dialog
      :visible="addTicketLineModalVisible"
      :modal="true"
      :style="{ width: '40vw' }"
      @update:visible="addTicketLineModalVisible = $event.value"
    >
      <div id="form">
        <label for="comment">Comentario:</label>
        <Textarea id="comment" v-model="ticketLine" class="ticket-textarea" />
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          @click="addTicketLineModalVisible = false"
        />
        <Button
          label="Crear"
          icon="pi pi-check"
          @click="addLine"
          :disabled="addLineButtonDisabled || ticketsLoading"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount, onMounted } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import DataTable from "primevue/datatable";
import Chip from "primevue/chip";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { useToast } from "primevue/usetoast";

import { createTicket, getTickets, addTicketLine } from "@/api/ticket";
import { useTicketStore } from "@/stores/ticket";
import { formatDateTime } from "../../services/utils";
import { readUserData } from "../../services/user";

const tickets = ref([]);
const totalItems = ref(0);
const currentPage = ref(1);
const ticketsLoading = ref(false);
const ticketLinesModalVisible = ref(false);
const ticketLines = ref([]);
const ticketHistory = ref([]);
const addTicketLineModalVisible = ref(false);
const currentTicket = ref(null);
const dateSort = ref("DESC");

const createTicketModalVisible = ref(false);
const shipmentId = ref("");
const deliveryNumber = ref("");
const comment = ref("");
const ticketLine = ref("");
const toast = useToast();
const ticketStore = useTicketStore();

const statusFilter = ref([
  { name: "Creado", code: 1 },
  { name: "En curso", code: 2 },
  { name: "Cerrado", code: 3 },
]);

const selectedStatusFilter = ref();

onMounted(() => {
  if (ticketStore.hasShipmentId) {
    shipmentId.value = ticketStore.shipmentId;
    deliveryNumber.value = ticketStore.deliveryNumber;
    openModal();
  } else if (ticketStore.hasManifestNumber) {
    comment.value = `Hola, necesito ayuda con el manifiesto N° ${ticketStore.manifestNumber}`;
    createTicketModalVisible.value = true;
  }

  getTicketsPage();
});

const getTicketsPage = async () => {
  try {
    const pageSize = 10;
    ticketsLoading.value = true;

    const formattedStatusFilter = selectedStatusFilter.value
      ? [selectedStatusFilter.value.code]
      : null;

    const { data } = await getTickets(
      currentPage.value,
      pageSize,
      dateSort.value,
      formattedStatusFilter
    );

    tickets.value = data.tickets;
    totalItems.value = Number(data.totalItems);
    ticketsLoading.value = false;
  } catch (error) {
    showToast("error", "Error", "No se pudieron obtener los tickets");
  }
};

const onPageChange = (page) => {
  currentPage.value = page;
  getTicketsPage();
};

const createButtonDisabled = computed(() => {
  if (showShipmentId.value) {
    return shipmentId.value === "" || comment.value === "";
  } else {
    return comment.value === "";
  }
});

const addLineButtonDisabled = computed(() => {
  return ticketLine.value === "";
});

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 7000,
  });
};

const showShipmentId = computed(() => {
  return ticketStore.hasShipmentId;
});

const save = async () => {
  const ticket = {
    shipment_id: shipmentId.value || null,
    comment: comment.value,
  };

  try {
    ticketsLoading.value = true;
    await createTicket(ticket);
    showToast("success", "Éxito", "Ticket creado correctamente");
    await getTicketsPage();
    ticketStore.setShipmentId(null);
    createTicketModalVisible.value = false;
  } catch (error) {
    showToast("error", "Error", "No se pudo crear el ticket");
  }
};

const addLine = async () => {
  const payload = {
    comment: ticketLine.value,
  };

  try {
    ticketsLoading.value = true;
    await addTicketLine(currentTicket.value.id, payload).then(() => {
      showToast("success", "Éxito", "Comentario agregado correctamente");
      ticketLine.value = "";
    });

    getTicketsPage();

    const userData = readUserData();
    ticketLines.value.unshift({
      comment: payload.comment,
      user_name: userData.name + " " + userData.last_name,
      created_at: new Date(),
    });
  } catch (error) {
    showToast("error", "Error", "No se pudo agregar el comentario");
  }

  addTicketLineModalVisible.value = false;
};

const openModal = () => {
  flush();
  createTicketModalVisible.value = true;
};

const openCommentsModal = (ticket) => {
  ticketLinesModalVisible.value = true;
  ticketLines.value = ticket.lines;
  ticketHistory.value = ticket.history;
  currentTicket.value = ticket;
};

const flush = () => {
  if (!showShipmentId.value) {
    shipmentId.value = "";
  }
  comment.value = "";
};

onBeforeUnmount(() => {
  ticketStore.setShipmentId(null);
});

const onSortChange = (event) => {
  event.sortOrder = dateSort.value;
  if (dateSort.value === "DESC") {
    dateSort.value = "ASC";
  } else {
    dateSort.value = "DESC";
  }

  getTicketsPage();
};
</script>

<style scoped>
#form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ticket-textarea {
  height: 150px;
}

.change-page-btn,
.change-page-btn:hover,
.change-page-btn:focus {
  background-color: #fff;
  color: var(--primary-color);
  box-shadow: none;
}

.page-btn {
  color: #ccc;
}

.page-btn,
.page-btn:hover,
.page-btn:focus {
  background-color: #fff;
  color: #ccc;
  border: none;
  outline: none;
  box-shadow: none;
}

.current-page-btn,
.current-page-btn:hover,
.current-page-btn:focus {
  color: var(--primary-color);
}

.ticket-line {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.blank-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  color: #ccc;
  font-size: 16px;
}

.p-chip {
  padding: 0 0.75rem;
}

.status-2 {
  background-color: #fff9e6;
  color: #f5a623;
}
.status-3 {
  background-color: #e6ffed;
  color: #52c41a;
}
.history {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-movements {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.p-dropdown {
  width: 200px;
  margin-bottom: 2rem;
}
</style>
