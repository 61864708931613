import axios from "./axios-instance";

export const createTicket = (payload) => {
  return axios.post("/tickets", payload);
};

export const getTickets = (page, pageSize, dateSort, statuses) => {
  return axios.post("/tickets/get", {
    page,
    pageSize,
    dateSort,
    statuses,
  });
};

export const addTicketLine = (ticketId, payload) => {
  return axios.post(`/tickets/${ticketId}/comment`, payload);
};
